import { useEffect, useState, useRef } from 'react'
import ScreenWrapper from './ScreenWrapper'

import QueueAnim from 'rc-queue-anim'
import './css/screen03.css'
import avatar from './assest/avatar.svg'
import clty from './assest/clty.svg'
import kol from './assest/kol.svg'
import DAO from './assest/DAO.svg'
import vr from './assest/vr.svg'
import SwiperCore, { Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'
// import "swiper/css/scrollbar"
import { useTranslation } from 'react-i18next'

SwiperCore.use([Scrollbar])
const icons = [avatar, clty, kol, DAO, vr]

const introduceData = [
    {
        title: '“Online Forever” Avatar',
        text:
            'Buying an avatar on the market, or creating a personalized avatar through face-squeezing tools, wearing real-world brand virtual clothing, feeding your virtual pet, and choosing a career path for your virtual being, these are the virtual life in Realy Metaverse. \nWhen you are online, the avatar is you, bringing you an immersive experience. When you are offline, the avatar wins himself back, a virtual being in the city of Realy Metaverse, with a set of AI-driven self-discipline systems.',
        url:
            'https://cdn.jsdelivr.net/gh/realy-official-website/official-website-new-assets/avatar.mp4'
    },
    {
        title: '"Metabolism" virtual city: land assets',
        text:
            'In the “Metabolism” virtual city, architecture is like life, born, grows, and disappears. The ecosystem is no longer the surplus and supplement of the building, but a symbiosis with the building. Wherever the human footprint goes, the ecosystem will develop  with. From blocks, buildings, stores, galleries to apartments, they are all scarce virtual estate NFTs. Building and decorating it, leasing it to brands, or operating it to get NFT sales revenue, all these activities will get $REAL rewarded by the CityDAO. ',
        url:
            'https://cdn.jsdelivr.net/gh/realy-official-website/official-website-new-assets/cityland.mp4'
    },
    {
        title: 'Cool" virtual concert: brands, artists and KOLs',
        text:
            'The virtual idol is the most shining star in Realy Metaverse and is the goal that every virtual being dreams of. A cool virtual concert held on the island can not only bring real artists and fans into the virtual world, but also attract brands to advertise, implant, issue virtual goods, and interact with users for fun.',
        url:
            'https://cdn.jsdelivr.net/gh/realy-official-website/official-website-new-assets/artists.mp4'
    },
    {
        title: 'Free and Democratic" virtual community: City DAO',
        text:
            'The entire virtual world is managed and operated by a decentralized DAO organization, and players can freely register, join and exit the autonomous organization. From the tax collection and distribution mechanism of Metaverse DAO and City DAO to the specific rules of specific industry unions, interest unions, and owner committees, players can participate in community governance through democratic voting.',
        url:
            'https://cdn.jsdelivr.net/gh/realy-official-website/official-website-new-assets/citydao.mp4'
    },
    {
        title: 'Integration of virtual and reality',
        text:
            'In the city of Realy Metaverse, you can buy a virtual garment of your favorite brand and send the real one to your real home.  In the real-world store of the brand, you can also claim your virtual clothing NFT and wear it on your avatar by scanning the NFC chip embedded in the real clothes. You can share both virtual and reality with your avatar.',
        url:
            'https://cdn.jsdelivr.net/gh/realy-official-website/official-website-new-assets/clothingnft.mp4'
    }
]
const Screen03 = ({ fullpageclass, index, currentIndex }) => {
    const subwidth = document.body.clientWidth - 40
    const swiper = useRef(null)
    const [itemIndex, setItemIndex] = useState(0)
    const myVideo = useRef(null)
    const { t } = useTranslation('screen03')
    if (myVideo.current) myVideo.current.play()

    function pagging(num) {
        if (num < 0) {
            return setItemIndex(introduceData.length - 1)
        }
        if (num === introduceData.length) {
            return setItemIndex(0)
        }
        setItemIndex(num)
    }
    function slideTo(num) {
        setItemIndex(num)
        if (swiper.current.swiper.slideTo) {
            swiper.current.swiper.slideTo(num)
        }
    }
    useEffect(() => {
        if (myVideo.current) myVideo.current.play()
    }, [itemIndex, myVideo])
    const [showAnimation, setShowAnimation] = useState(false)
    useEffect(() => {
        if (index === currentIndex) {
            setShowAnimation(true)
        } else {
            setShowAnimation(false)
        }
    }, [currentIndex, index])
    return (
        <ScreenWrapper id="screen03" fullpageclass={fullpageclass}>
            <div className="screen03-warp">
                {index !== currentIndex ? (
                    <div className='screen03-warp opacity-0'>
                        <h1 className="title">Real Realy</h1>
                        <div className="screen03-layout ">
                            <div className="screen03-layout-left">
                                <div>
                                    <h1
                                        key={`item${itemIndex} h1`}
                                        className="screen03-title text-md">
                                        {t(`title${itemIndex + 1}`)}
                                    </h1>
                                    {introduceData.map((v, i) => {
                                        return (
                                            <div key={i} className="screen03-introduce">
                                                {itemIndex === i && (
                                                    <QueueAnim
                                                        name="QueueAnim"
                                                        duration="1000"
                                                        type="bottom"
                                                        ease={['easeOutQuart', 'easeInOutQuart']}
                                                        className="text-opacity-70 p-0"
                                                        animation={{ opacity: [1, 0] }}>
                                                        <p
                                                            key={`item${i} text`}
                                                            className="screen03-introduce-p">
                                                            {t(`text${i + 1}`)}
                                                        </p>
                                                    </QueueAnim>
                                                )}
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="screen03-introduce-button-warp">
                                    {icons.map((v, i) => {
                                        return (
                                            <button
                                                onClick={() => slideTo(i)}
                                                key={`${v}${i}`}
                                                className={`screen03-introduce-button  ${
                                                    itemIndex === i ? 'bg-white bg-opacity-10' : ''
                                                }`}>
                                                <img src={v} alt="" width="32" height="32" />
                                            </button>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="screen03-introduce-warp">
                                {introduceData.map((v, i) => {
                                    return (
                                        <div key={i} className="screen03-introduce-warp-video">
                                            {itemIndex === i && (
                                                <QueueAnim
                                                    name="QueueAnim"
                                                    duration="1000"
                                                    type="right"
                                                    ease={['easeOutQuart', 'easeInOutQuart']}
                                                    animation={{ opacity: [1, 0], delay: 200 }}>
                                                    <video
                                                        className="screen03-introduce-video"
                                                        ref={myVideo}
                                                        // key={`video ${i}`}
                                                        autoPlay
                                                        loop
                                                        muted>
                                                        <source src={v.url} type="video/mp4" />
                                                    </video>
                                                </QueueAnim>
                                            )}
                                        </div>
                                    )
                                })}
                                <div className="screen03-show-model-tap">
                                    <button
                                        onClick={() => pagging(itemIndex - 1)}
                                        className="screen03-show-model-button-left">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6 text-white"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M15 19l-7-7 7-7"
                                            />
                                        </svg>
                                    </button>
                                    <button
                                        onClick={() => pagging(itemIndex + 1)}
                                        className="screen03-show-model-button-right ">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6 text-white"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M9 5l7 7-7 7"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div className="screen03-switch-warp">
                                <div className="screen03-switch-button">
                                    {icons.map((v, i) => {
                                        return (
                                            <button
                                                onClick={() => slideTo(i)}
                                                key={`${v}${i}`}
                                                className={`screen03-switch-button-list ${
                                                    itemIndex === i ? 'bg-white bg-opacity-10' : ''
                                                }`}>
                                                <img src={v} alt="" width="32" height="32" />
                                            </button>
                                        )
                                    })}
                                </div>
                                <div className="screen03-video-warp">
                                    <Swiper
                                        className="mySwiper03"
                                        // style={{width: 'auto', margin: 0}}
                                        scrollbar={{
                                            hide: false
                                        }}
                                        ref={swiper}
                                        spaceBetween={10}
                                        slidesPerView={'auto'}
                                        pagination={{ clickable: true }}
                                        // watchSlidesProgress
                                        onSlideChange={({ activeIndex }) =>
                                            setItemIndex(activeIndex)
                                        }
                                        // onSwiper={swiper => console.log(swiper)}
                                    >
                                        {introduceData.map((v, i) => {
                                            return (
                                                <SwiperSlide
                                                    key={v.title}
                                                    style={{ width: subwidth }}>
                                                    <div className="screen03-video-warp-switch">
                                                        <div className="screen03-video-warp-switch-div1">
                                                            {v.title}
                                                        </div>
                                                        <div className="screen03-video-warp-switch-div2">
                                                            {v.text}
                                                        </div>
                                                        <div className="screen03-warp-switch-video-div">
                                                            <video
                                                                className="screen03-warp-switch-video"
                                                                ref={myVideo}
                                                                key={`video ${i}`}
                                                                autoPlay
                                                                loop
                                                                muted>
                                                                <source
                                                                    src={v.url}
                                                                    type="video/mp4"
                                                                />
                                                            </video>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <QueueAnim
                        className="w-screen"
                        animConfig={[
                            { opacity: [1, 0], translateY: [0, 50] },
                            { opacity: [1, 0], translateY: [0, -50] }
                        ]}
                        ease={['easeOutQuart', 'easeInOutQuart']}>
                        {showAnimation ? (
                            <div key="screen03">
                                <h1 className="title">Real Realy</h1>
                                <div className="screen03-layout ">
                                    <div className="screen03-layout-left">
                                        <div>
                                            <h1
                                                key={`item${itemIndex} h1`}
                                                className="screen03-title text-md">
                                                {t(`title${itemIndex + 1}`)}
                                            </h1>
                                            {introduceData.map((v, i) => {
                                                return (
                                                    <div key={i} className="screen03-introduce">
                                                        {itemIndex === i && (
                                                            <QueueAnim
                                                                name="QueueAnim"
                                                                duration="1000"
                                                                type="bottom"
                                                                ease={[
                                                                    'easeOutQuart',
                                                                    'easeInOutQuart'
                                                                ]}
                                                                className="text-opacity-70 p-0"
                                                                animation={{
                                                                    opacity: [1, 0]
                                                                }}>
                                                                <p
                                                                    key={`item${i} text`}
                                                                    className="screen03-introduce-p">
                                                                    {t(`text${i + 1}`)}
                                                                </p>
                                                            </QueueAnim>
                                                        )}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className="screen03-introduce-button-warp">
                                            {icons.map((v, i) => {
                                                return (
                                                    <button
                                                        onClick={() => slideTo(i)}
                                                        key={`${v}${i}`}
                                                        className={`screen03-introduce-button  ${
                                                            itemIndex === i
                                                                ? 'bg-white bg-opacity-10'
                                                                : ''
                                                        }`}>
                                                        <img
                                                            src={v}
                                                            alt=""
                                                            width="32"
                                                            height="32"
                                                        />
                                                    </button>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className="screen03-introduce-warp">
                                        {introduceData.map((v, i) => {
                                            return (
                                                <div
                                                    key={i}
                                                    className="screen03-introduce-warp-video">
                                                    {itemIndex === i && (
                                                        <QueueAnim
                                                            name="QueueAnim"
                                                            duration="1000"
                                                            type="right"
                                                            ease={[
                                                                'easeOutQuart',
                                                                'easeInOutQuart'
                                                            ]}
                                                            animation={{
                                                                opacity: [1, 0],
                                                                delay: 200
                                                            }}>
                                                            <video
                                                                className="screen03-introduce-video"
                                                                ref={myVideo}
                                                                // key={`video ${i}`}
                                                                autoPlay
                                                                loop
                                                                muted>
                                                                <source
                                                                    src={v.url}
                                                                    type="video/mp4"
                                                                />
                                                            </video>
                                                        </QueueAnim>
                                                    )}
                                                </div>
                                            )
                                        })}
                                        <div className="screen03-show-model-tap">
                                            <button
                                                onClick={() => pagging(itemIndex - 1)}
                                                className="screen03-show-model-button-left">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-6 w-6 text-white"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor">
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M15 19l-7-7 7-7"
                                                    />
                                                </svg>
                                            </button>
                                            <button
                                                onClick={() => pagging(itemIndex + 1)}
                                                className="screen03-show-model-button-right ">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-6 w-6 text-white"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor">
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M9 5l7 7-7 7"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="screen03-switch-warp">
                                        <div className="screen03-switch-button">
                                            {icons.map((v, i) => {
                                                return (
                                                    <button
                                                        onClick={() => slideTo(i)}
                                                        key={`${v}${i}`}
                                                        className={`screen03-switch-button-list ${
                                                            itemIndex === i
                                                                ? 'bg-white bg-opacity-10'
                                                                : ''
                                                        }`}>
                                                        <img
                                                            src={v}
                                                            alt=""
                                                            width="32"
                                                            height="32"
                                                        />
                                                    </button>
                                                )
                                            })}
                                        </div>
                                        <div className="screen03-video-warp">
                                            <Swiper
                                                className="mySwiper03"
                                                // style={{width: 'auto', margin: 0}}
                                                scrollbar={{
                                                    hide: false
                                                }}
                                                ref={swiper}
                                                spaceBetween={10}
                                                slidesPerView={'auto'}
                                                pagination={{ clickable: true }}
                                                // watchSlidesProgress
                                                onSlideChange={({ activeIndex }) =>
                                                    setItemIndex(activeIndex)
                                                }
                                                onSwiper={swiper => console.log(swiper)}>
                                                {introduceData.map((v, i) => {
                                                    return (
                                                        <SwiperSlide
                                                            key={v.title}
                                                            style={{ width: subwidth }}>
                                                            <div className="screen03-video-warp-switch">
                                                                <div className="screen03-video-warp-switch-div1">
                                                                    {v.title}
                                                                </div>
                                                                <div className="screen03-video-warp-switch-div2">
                                                                    {v.text}
                                                                </div>
                                                                <div className="screen03-warp-switch-video-div">
                                                                    <video
                                                                        className="screen03-warp-switch-video"
                                                                        ref={myVideo}
                                                                        key={`video ${i}`}
                                                                        autoPlay
                                                                        loop
                                                                        muted>
                                                                        <source
                                                                            src={v.url}
                                                                            type="video/mp4"
                                                                        />
                                                                    </video>
                                                                </div>
                                                            </div>
                                                        </SwiperSlide>
                                                    )
                                                })}
                                            </Swiper>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </QueueAnim>
                )}
            </div>
        </ScreenWrapper>
    )
}

export default Screen03
