import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
// en
import common_en from './en/common.json'
import screen01_en from './en/screen01.json'
import screen02_en from './en/screen02.json'
import screen03_en from './en/screen03.json'
import screen05_en from './en/screen05.json'
import screen06_en from './en/screen06.json'
import screen07_en from './en/screen07.json'
import screen08_en from './en/screen08.json'
// zh
import common_zh from './zh/common.json'
import screen01_zh from './zh/screen01.json'
import screen02_zh from './zh/screen02.json'
import screen03_zh from './zh/screen03.json'
import screen05_zh from './zh/screen05.json'
import screen06_zh from './zh/screen06.json'
import screen07_zh from './zh/screen07.json'
import screen08_zh from './zh/screen08.json'
i18n.use(LanguageDetector)
    .use(initReactI18next) // bind react-i18next to the instance
    .init({
        resources: {
            en: {
                common: common_en,
                screen01: screen01_en,
                screen02: screen02_en,
                screen03: screen03_en,
                screen05: screen05_en,
                screen06: screen06_en,
                screen07: screen07_en,
                screen08: screen08_en


            },
            zh: {
                common: common_zh,
                screen01: screen01_zh,
                screen02: screen02_zh,
                screen03: screen03_zh,
                screen05: screen05_zh,
                screen06: screen06_zh,
                screen07: screen07_zh,
                screen08: screen08_zh
            }
        },
        lng: 'en',
        debug: true,
        interpolation: {
            escapeValue: false // not needed for react!!
        }
        // react i18next special options (optional)
        // override if needed - omit if ok with defaults
        /*
    react: {
        bindI18n: 'languageChanged',
        bindI18nStore: '',
        transEmptyNodeValue: '',
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
        useSuspense: true,
    }
    */
    })

export default i18n
