import { Fragment } from 'react'
import realyFooterLogo from '../assest/realyFooterLogo.svg'
import SocialIcon from '../../components/SocialIcon'
import '../css/RealyFooter.css'
const socialLinks = {
    twitter: 'https://twitter.com/RealyOfficial',
    discord: 'https://t.co/e40zcAKPGH',
    telegram: 'https://t.me/realyofficial',
    instagram: 'https://www.instagram.com/realy.pro/',
    medium: 'https://medium.com/@realy'
}

const RealyFooter = () => {
    const showSocialList = ['twitter', 'discord', 'medium', 'telegram', 'instagram']
    return (
        <div className="w-full flex flex-col justify-center items-center"  >
            <div className="w-full flex justify-between social-btn-wrap-footer pt-24 border-b border-white border-opacity-10 pb-12">
                {showSocialList.map((social) => (
                    <Fragment key={social}>
                        <a
                            href={socialLinks[social]}
                            target="__blank"
                            className="rounded-full bg-white bg-opacity-10 w-12 h-12 box-border p-1">
                            <SocialIcon name={social} />
                        </a>
                    </Fragment>
                ))}
            </div>
            <div  className="pt-16">
                <img alt="" src={realyFooterLogo} />
            </div>
            <div className="py-7 opacity-70" >info@realy.pro</div>
            <div className="opacity-70 text-sm pb-16">© 2021 realy metaverse.</div>
        </div>
    )
}

export default RealyFooter
