import ScreenWrapper from './ScreenWrapper'
import { useRef, useState, useEffect } from 'react'
import './css/screen01.css'
import webm from './back.webm'
import Player from '../components/Player'
import { useTranslation } from 'react-i18next'
// import TweenOne from 'rc-tween-one'
import Download from '../components/DownloadAPP'
import QueueAnim from 'rc-queue-anim'
// import { opacity } from 'tailwindcss/defaultTheme'
const Screen01 = ({fullpageclass, index, currentIndex}) => {
    const { t } = useTranslation('screen01')
    const myVideo = useRef(null)
    const myVideo2 = useRef(null)
    const [showAnimation, setShowAnimation] = useState(false)
    const [showDownLoadModal,setShowDownLoad]=useState(false)
    if (myVideo.current) myVideo.current.play()
    if (myVideo2.current) myVideo2.current.play()

    // useEffect(() => {
    //     console.log(13123123)
    //         myVideo.current.play()
    // }, [])

    const [isShow, setDisplayVideo] = useState(false)

    const toggleModal = () => {
        setDisplayVideo(!isShow)
    }
    const downLoadModal=()=>{
        setShowDownLoad(!showDownLoadModal)
    }
    useEffect(() => {
        if (index === currentIndex) {
            setShowAnimation(true)
        } else {
            setShowAnimation(false)
        }
    }, [currentIndex, index])
    return (
        <ScreenWrapper fullpageclass={fullpageclass} style={{ zIndex: 50 }}>
            <div className="bg-black opacity-50">
                <video
                    ref={myVideo}
                    className="full-video-bg"
                    autoPlay
                    loop
                    muted
                    width="1920"
                    height="1024">
                    <source src={webm} type="video/webm" />
                </video>
            </div>
            <QueueAnim
                animConfig={[
                    { opacity: [1, 0], translateY: [0, 50] },
                    { opacity: [1, 0], translateY: [0, -50] }
                ]}
                ease={['easeOutQuart', 'easeInOutQuart']}>
                {showAnimation ? (
                    <div className="screen01" key="screen01" id="screen01">
                        <div className="screen01-warp">
                            <h1 className="screen01-warp-title">
                                {t('title')}
                            </h1>
                            <div className="screen01-warp-summary">
                                {t('summary')}
                            </div>
                            <div className="screen01-warp-button">
                                <button
                                    onClick={toggleModal}
                                    className="screen01-warp-button-play">
                                    {t('play')}
                                </button>

                                <a
                                    // href="/whitepaper.pdf"
                                    onClick={downLoadModal}
                                    className="screen01-warp-button-whitepaper">
                                    {t('download')}
                                </a>
                            </div>
                        </div>
                    </div>) : null}
            </QueueAnim>
            <Player
                open={isShow}
                toggleModal={toggleModal}
                url="https://cdn.jsdelivr.net/gh/realy-official-website/official-website-new-assets/kooola_trailer_v08_cc_h264.webm"
            />
            <Download
                open={showDownLoadModal}
                toggleModal={downLoadModal}
            />
        </ScreenWrapper>
    )
}

export default Screen01
