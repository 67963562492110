import { useState } from 'react'
import Modal from 'react-responsive-modal'
import real from '../../screen/assest/download_app/real.svg'
import XCircle from '../../screen/assest/download_app/XCircle.png'
import apple from '../../screen/assest/download_app/apple.svg'

import android from '../../screen/assest/download_app/android.svg'
import android_white from '../../screen/assest/download_app/android-white.svg'
import './style.css'

//download app
const DownloadApp = ({ open, toggleModal }) => {
    const closeIcon = <img alt="" src={XCircle} />
    const [isHoverAndroid, setHoverAndroid] = useState(false)
    const [isHoverAppleGlobal, setHoverAppleGlobal] = useState(false)
    const [isHoverAppleZH, setHoverAppleZH] = useState(false)
    return (
        <Modal
            open={open}
            onClose={toggleModal}
            closeIcon={closeIcon}
            blockScroll={false}
            classNames={{
                overlay: 'customOverlay',
                modal: 'customModal-appdown',
                closeButton: 'customCloseButton-appdown'
                
            }}
            center>
            <div>
                <div className="downloadTitle">Download realy APP</div>
                <div className="flex justify-center justify-items-center mt-20">
                    <div>
                        <img alt="" src={real} />
                    </div>
                </div>
                <div className="flex flex-col text-center  ">
                    <div className="font-bold text-xl text-white mt-6">REALY</div>
                    <div className="mt-1 font-normal text-lg text-white">real recognize real</div>
                </div>
                <div className="flex flex-col md:divide-x  md:flex-row justify-between text-white mt-20">
                    <div
                        onClick={() => (window.location = 'https://play.google.com/store/apps/details?id=com.kooola.realy')}
                        onMouseEnter={() => setHoverAndroid(true)}
                        onMouseLeave={() => setHoverAndroid(false)}
                        className="cursor-pointer mt-4 md:mt-0 border rounded-3xl md:border-none h-12 md:h-full flex flex-row md:flex-col  md:space-y-4 justify-center items-center">
                        <img style={{height:"24px",width:"21px"}} alt="" src={isHoverAndroid ? android_white : android} />

                        <div>Android</div>
                    </div>

                    <div
                        onClick={() =>
                            (window.location =
                                'https://apps.apple.com/hk/app/realy-global/id1546030411?l=en')
                        }
                        onMouseEnter={() => setHoverAppleGlobal(true)}
                        onMouseLeave={() => setHoverAppleGlobal(false)}
                        className="cursor-pointer mt-4 md:mt-0 border rounded-3xl md:border-none h-12 md:h-full flex flex-row md:flex-col md:space-y-4 justify-center items-center">
                        <img
                            alt=""
                            className={isHoverAppleGlobal ? '' : 'opacity-50'}
                            src={apple}
                        />
                        <div>IOS Global</div>
                    </div>

                    <div
                        onClick={() =>
                            (window.location =
                                'https://apps.apple.com/cn/app/realy-real/id1542320772?l=en')
                        }
                        onMouseEnter={() => setHoverAppleZH(true)}
                        onMouseLeave={() => setHoverAppleZH(false)}
                        className="cursor-pointer mt-4 md:mt-0  border rounded-3xl md:border-none h-12 md:h-full flex flex-row md:flex-col    md:space-y-4 justify-center items-center">
                        <img alt="" className={isHoverAppleZH ? '' : 'opacity-50'} src={apple} />
                        <div>IOS China</div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default DownloadApp
