import { Component } from 'react'
import ReactPlayer from 'react-player'
import Modal from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import './style.css'

class Player extends Component {
    render() {
        const { open, toggleModal, url } = this.props
        return (
            <Modal
                open={open}
                onClose={toggleModal}
                classNames={{
                    overlay: 'customOverlay',
                    modal: 'customModal',
                    closeButton: 'customCloseButton'
                }}
                center>
                <ReactPlayer playing loop controls width="100%" height="100%" url={url} />
            </Modal>
        )
    }
}

export default Player
