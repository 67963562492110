import { useEffect, useState } from 'react'
import ScreenWrapper from './ScreenWrapper'
import './css/screen07.css'
import investors_logo1 from './assest/inver/investors_logo1.png'
import investors_logo2 from './assest/inver/investors_logo2.png'
import investors_logo3 from './assest/inver/investors_logo3.png'
import investors_logo4 from './assest/inver/investors_logo4.png'
import investors_logo5 from './assest/inver/investors_logo5.png'
import investors_logo6 from './assest/inver/investors_logo6.png'
import investors_logo7 from './assest/inver/investors_logo7.png'
import investors_logo8 from './assest/inver/investors_logo8.png'
import investors_logo9 from './assest/inver/investors_logo9.png'
import investors_logo10 from './assest/inver/investors_logo10.png'
import investors_logo11 from './assest/inver/investors_logo11.png'
import investors_logo12 from './assest/inver/investors_logo12.png'
import investors_logo13 from './assest/inver/investors_logo13.png'
import investors_logo14 from './assest/inver/investors_logo14.png'
import investors_logo15 from './assest/inver/investors_logo15.png'
import investors_logo16 from './assest/inver/investors_logo16.png'
import investors_logo17 from './assest/inver/investors_logo17.png'
import { useTranslation } from 'react-i18next'
import QueueAnim from 'rc-queue-anim'
const Screen07 = ({ fullpageclass, index, currentIndex }) => {
    const { t } = useTranslation('screen07')
    const Investor = [
        investors_logo1,
        investors_logo2,
        investors_logo3,
        investors_logo4,
        investors_logo5,
        investors_logo6,
        investors_logo7,
        investors_logo8,
        investors_logo9,
        investors_logo10,
        investors_logo11,
        investors_logo12,
        investors_logo13,
        investors_logo14,
        investors_logo15,
        investors_logo17,
        investors_logo16
    ]
    const [showAnimation, setShowAnimation] = useState(false)
    useEffect(() => {
        if (index === currentIndex) {
            setShowAnimation(true)
        } else {
            setShowAnimation(false)
        }
    }, [currentIndex, index])
    return (
        <ScreenWrapper id="screen06" fullpageclass={fullpageclass}>
            <div className="screen07-warp">
                {index !== currentIndex ? (
                    <div className="opacity-0">
                        <h1 className="screen07-warp-h1 ">{t('title')}</h1>
                        <div className="screen07-warp-img-div u-screen07-item">
                            {Investor.map((v, index) => (
                                <div key={index} className={`screen07-warp-img`}>
                                    <img className="mx-auto" alt="" src={v} />
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <QueueAnim
                        animConfig={[
                            { opacity: [1, 0], translateY: [0, 50] },
                            { opacity: [1, 0], translateY: [0, -50] }
                        ]}
                        ease={['easeOutQuart', 'easeInOutQuart']}>
                        {showAnimation ? (
                            <div key="screen07">
                                <h1 className="screen07-warp-h1">{t('title')}</h1>
                                <div className="screen07-warp-img-div u-screen07-item">
                                    {Investor.map((v, index) => (
                                        <div key={index} className={`screen07-warp-img`}>
                                            <img className="mx-auto" alt="" src={v} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : null}
                    </QueueAnim>
                )}
            </div>
        </ScreenWrapper>
    )
}

export default Screen07
