// import { useState } from 'react'
import ScreenWrapper from './ScreenWrapper'
import FDetails08 from './compent/FDetails08'
// import { isMobile } from 'react-device-detect'
import RealyFooter from './compent/RealyFooter'
import { useTranslation } from 'react-i18next'
import './css/screen08.css'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

const Screen08 = ({ fullpageclass }) => {
    const { t } = useTranslation('screen08')

    return (
        <ScreenWrapper id="screen07" fullpageclass={fullpageclass}>
            <div className="screen08-warp">
                <div className="screen08-warp-map">
                    <h1 className="screen08-warp-map-h1">{t('title')} </h1>
                    <div
                        className="screen08-warp-map-subtitle"
                    >
                        {t('subtitle')}
                    </div>
                    <FDetails08 />
                </div>
                <div className="screen08-warp-bottom">
                    <div className="screen08-warp-bottom-text">
                        <div>© 2021 realy metaverse</div>
                        <div>info@realy.pro</div>
                    </div>
                </div>
                <div className="screen08-warp-footer ">
                    <RealyFooter />
                </div>
                {/* {isMobile ? (
                    <div className="w-full m-auto bottom-10 ">
                        <RealyFooter />
                    </div>
                ) : (
                    <div className="absolute m-auto w-1200 bottom-5 ">
                        <div className="flex justify-between opacity-75">
                            <div>© 2021 realy metaverse</div>
                            <div>info@realy.pro</div>
                        </div>
                    </div>
                )} */}
            </div>
        </ScreenWrapper>
    )
}

export default Screen08
